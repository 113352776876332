export const storeToken = (token) => {
  localStorage.setItem("accessToken", token);
};

export const getToken = () => {
  let token = localStorage.getItem("accessToken");
  if (token) {
    token = JSON.parse(token);
  }
  return token;
};

export const storeLoginDetail = (loginDetail) => {
  localStorage.setItem("loginDetail", JSON.stringify(loginDetail));
};

export const getLoginDetail = () => {
  let loginDetail = localStorage.getItem("loginDetail");
  if (loginDetail) {
    loginDetail = JSON.parse(loginDetail);
  }
  return loginDetail;
};
