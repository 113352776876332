import React from "react";
import { useAtom } from "jotai";
import { loginAtom, signUpEmailAtom} from "../authAtom";

const useAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useAtom(loginAtom);
  const [signUpEmail, setSignUpEmail] = useAtom(signUpEmailAtom);
  return { isLoggedIn, setIsLoggedIn, signUpEmail, setSignUpEmail };
};

export default useAuth;
