import React from "react";
import { Provider } from "jotai";
import { BrowserRouter } from "react-router-dom";
import NetworkInterceptors from "api/network/NetworkInterceptors";
import LoaderContainer from "components/common/loader/LoaderContainer";
import AppController from "./AppController";
import { initializeIcons } from "utils/IconUtil";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { purple } from "@mui/material/colors";
import { SnackbarProvider } from "notistack";

initializeIcons();

const theme = createTheme({
  palette: {
    primary: {
      main: "#2A0E9C",
    },
    secondary: {
      main: "#DDD4FA",
    },
  },
  typography: {
    button: {
      textTransform: "none",
      fontWeight: 400,
    },
    fontFamily: "Poppins",
  },
});

const MAX_TOAST_COUNT = 3;
const TOAST_HIDE_DURATION = 2000
const TOAST_ORIGIN = {
  vertical: "top",
  horizontal: "right",
}


const App = () => {
  return (
    <Provider>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={MAX_TOAST_COUNT}
            anchorOrigin={TOAST_ORIGIN}
            autoHideDuration={TOAST_HIDE_DURATION}
          >
            <NetworkInterceptors />
            <LoaderContainer />
            <AppController />
          </SnackbarProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
