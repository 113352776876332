import ReactDOM from "react-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheckSquare,
  faCheck,
  faBell,
  faCog,
  faWaveSquare,
  faCode,
  faHeadset,
  faTicketSimple,
  faArrowUpLong,
  faCalendar,
  faQuestionCircle,
  faCheckCircle,
  faClock,
  faEnvelope,
  faIdCard,
  faBuilding,
  faArrowRight,
  faPhone,
  faBriefcase,
  faAlignLeft,
  faAlignRight,
  faGlobe,
  faAt,
  faThumbsUp,
  faGear,
  faKey,
  faEye,
  faUser,
  faLock,
  faCircleCheck,
  faDatabase,
  faShield,
  faGauge,
  faPlug,
  faBackward,
  faDiagramProject,
  faEdit,
  faTimes,
  faChartBar,
  faChartLine,
  faTable,
} from "@fortawesome/free-solid-svg-icons";

import { faBuilding as rgFaBuilding } from "@fortawesome/free-regular-svg-icons";

export const initializeIcons = () => {
  library.add(
    faCheckSquare,
    faCheck,
    faBell,
    faCog,
    faWaveSquare,
    faCode,
    faHeadset,
    faTicketSimple,
    faArrowUpLong,
    faCalendar,
    faQuestionCircle,
    faCheckCircle,
    faClock,
    faEnvelope,
    faIdCard,
    faBuilding,
    faArrowRight,
    rgFaBuilding,
    faPhone,
    faBriefcase,
    faAlignLeft,
    faAlignRight,
    faGlobe,
    faAt,
    faThumbsUp,
    faGear,
    faKey,
    faEye,
    faUser,
    faLock,
    faCircleCheck,
    faDatabase,
    faShield,
    faGauge,
    faPlug,
    faBackward,
    faDiagramProject,
    faEdit,
    faTimes,
    faChartBar,
    faChartLine,
    faTable,
    faCog
  );
};
