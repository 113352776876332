import tw from "tailwind-styled-components";

export const LoaderWrapper = tw.div`
  z-2000
  h-full
  w-full
  fixed
  bg-backdrop
  flex
`;

