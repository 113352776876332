import { useSnackbar } from "notistack";

const useToast = () => {
    const { enqueueSnackbar } = useSnackbar();

    const showSuccessToast = (message, config= {}) => {
        enqueueSnackbar(message, { variant: "success", ...config });
    };

    const showErrorToast = (message, config= {}) => {
        enqueueSnackbar(message, { variant: "error", ...config });
    };

    const showWarningToast = (message, config= {}) => {
        enqueueSnackbar(message, { variant: "warning", ...config });
    };

    return {
        showSuccessToast,
        showErrorToast,
        showWarningToast,
    }
};

export default useToast;