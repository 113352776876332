import React from "react";
import { loaderCountAtom } from "atoms/loaderAtom";
import { useAtomValue } from "jotai/utils";

import { LoaderWrapper } from "./LoaderContainer.styles";
import CircularProgress from "@mui/material/CircularProgress";

// import Loader from 'components/assests/loader.svg'

const LoaderContainer = () => {
  const loaderCount = useAtomValue(loaderCountAtom);

  return (
    <>
      {loaderCount > 0 && (
        <LoaderWrapper>
          <div className="flex flex-col flex-1 justify-center items-center">
            <CircularProgress color="primary" />
          </div>
        </LoaderWrapper>
      )}
    </>
  );
};

export default LoaderContainer;
