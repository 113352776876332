import React, { lazy, Suspense } from "react";
import useAuth from "atoms/hooks/useAuth";
import CircularProgress from "@mui/material/CircularProgress";

const PreLogin = lazy(() => import("components/preLogin/PreLogin"));
const PostLogin = lazy(() => import("components/postLogin/PostLogin"));

const App = () => {
  const { isLoggedIn } = useAuth();
  return (
    <Suspense
      fallback={
        <div className="flex flex-col flex-1 justify-center items-center">
          <CircularProgress color="primary" />
        </div>
      }
    >
      <Choose>
        <When condition={isLoggedIn}>
          <PostLogin />
        </When>
        <Otherwise>
          <PreLogin />
        </Otherwise>
      </Choose>
    </Suspense>
  );
};

export default App;
