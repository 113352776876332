import React, { useEffect } from "react";
import { defaultAxios, isCancel } from "./helper";
import useLoader from "atoms/hooks/useLoader";
import useAuth from "atoms/hooks/useAuth";
import { getToken } from "utils/AuthUtils";
import useToast from "hooks/useToast";

const NetworkInterceptors = () => {
  const { showLoader, hideLoader } = useLoader();
  const { setIsLoggedIn, isLoggedIn } = useAuth();
  const { showErrorToast } = useToast();

  useEffect(() => {
    const onRequest = (config = {}) => {
      const { skipLoader = false } = config;
      if (!skipLoader) {
        showLoader({ customLoader: config.customLoader });
      }
      const headers = { ...config.headers };
      const token = getToken();
      if (token) {
        headers["Authorization"] = `Bearer ${token.accessToken}`;
      }
      return { ...config, headers: headers };
    };
    const onRequestError = (error) => {
      if (!error.config?.skipLoader) {
        hideLoader();
      }
      return Promise.reject(error);
    };

    const onResponse = (response) => {
      if (!response.config?.skipLoader) {
        hideLoader();
      }
      return response;
    };

    const isSessionInvalid = (error) => {
      return error?.response.status === 401;
    };

    const onResponseError = (error) => {
      if (isCancel(error)) {
        hideLoader();
      } else {
        if (!error.config?.skipLoader) {
          hideLoader();
        }

        if (isLoggedIn && isSessionInvalid(error)) {
          showErrorToast("Your session has been expired or invalid. Please login again.");
          setIsLoggedIn(false);
          localStorage.clear();
        }

        
      }
      return Promise.reject(error);
    };

    const requestInterceptors = defaultAxios.interceptors.request.use(
      onRequest,
      onRequestError
    );
    const responseInterceptors = defaultAxios.interceptors.response.use(
      onResponse,
      onResponseError
    );

    return () => {
      defaultAxios.interceptors.request.eject(requestInterceptors);
      defaultAxios.interceptors.response.eject(responseInterceptors);
    };
  }, []);

  return <></>;
};

export default NetworkInterceptors;
